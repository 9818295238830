exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agreements-js": () => import("./../../../src/pages/agreements.js" /* webpackChunkName: "component---src-pages-agreements-js" */),
  "component---src-pages-agreements-ru-js": () => import("./../../../src/pages/agreements.ru.js" /* webpackChunkName: "component---src-pages-agreements-ru-js" */),
  "component---src-pages-agreements-uz-js": () => import("./../../../src/pages/agreements.uz.js" /* webpackChunkName: "component---src-pages-agreements-uz-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-ru-js": () => import("./../../../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-index-uz-cyrillic-js": () => import("./../../../src/pages/index.uz-cyrillic.js" /* webpackChunkName: "component---src-pages-index-uz-cyrillic-js" */),
  "component---src-pages-index-uz-js": () => import("./../../../src/pages/index.uz.js" /* webpackChunkName: "component---src-pages-index-uz-js" */),
  "component---src-pages-list-js": () => import("./../../../src/pages/list.js" /* webpackChunkName: "component---src-pages-list-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-privacy-policy-personal-data-js": () => import("./../../../src/pages/privacy-policy-personal-data.js" /* webpackChunkName: "component---src-pages-privacy-policy-personal-data-js" */),
  "component---src-pages-privacy-policy-ru-js": () => import("./../../../src/pages/privacy-policy.ru.js" /* webpackChunkName: "component---src-pages-privacy-policy-ru-js" */),
  "component---src-pages-privacy-policy-uz-cyrillic-js": () => import("./../../../src/pages/privacy-policy.uz-cyrillic.js" /* webpackChunkName: "component---src-pages-privacy-policy-uz-cyrillic-js" */),
  "component---src-pages-privacy-policy-uz-js": () => import("./../../../src/pages/privacy-policy.uz.js" /* webpackChunkName: "component---src-pages-privacy-policy-uz-js" */),
  "component---src-pages-privacy-policy-yur-js": () => import("./../../../src/pages/privacy-policy-yur.js" /* webpackChunkName: "component---src-pages-privacy-policy-yur-js" */),
  "component---src-pages-privacy-policy-yur-ru-js": () => import("./../../../src/pages/privacy-policy-yur.ru.js" /* webpackChunkName: "component---src-pages-privacy-policy-yur-ru-js" */),
  "component---src-pages-privacy-policy-yur-uz-js": () => import("./../../../src/pages/privacy-policy-yur.uz.js" /* webpackChunkName: "component---src-pages-privacy-policy-yur-uz-js" */),
  "component---src-pages-public-offering-js": () => import("./../../../src/pages/public-offering.js" /* webpackChunkName: "component---src-pages-public-offering-js" */),
  "component---src-pages-public-offering-ru-js": () => import("./../../../src/pages/public-offering.ru.js" /* webpackChunkName: "component---src-pages-public-offering-ru-js" */),
  "component---src-pages-public-offering-uz-js": () => import("./../../../src/pages/public-offering.uz.js" /* webpackChunkName: "component---src-pages-public-offering-uz-js" */),
  "component---src-pages-video-registration-en-js": () => import("./../../../src/pages/video-registration.en.js" /* webpackChunkName: "component---src-pages-video-registration-en-js" */),
  "component---src-pages-video-registration-ru-js": () => import("./../../../src/pages/video-registration.ru.js" /* webpackChunkName: "component---src-pages-video-registration-ru-js" */),
  "component---src-pages-video-registration-uz-cyrillic-js": () => import("./../../../src/pages/video-registration.uz-cyrillic.js" /* webpackChunkName: "component---src-pages-video-registration-uz-cyrillic-js" */),
  "component---src-pages-video-registration-uz-js": () => import("./../../../src/pages/video-registration.uz.js" /* webpackChunkName: "component---src-pages-video-registration-uz-js" */)
}

